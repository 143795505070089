<template>
    <div class="selective-tab event-row-admin">
        <div class="tab-info">
            <div class="tab-title">
                <span class="event-type">{{ event.type }}</span> {{ event.name }}
            </div>
            <div class="event-time" :class="{ ended: eventEnded }">{{ eventTime }}</div>
            <div class="tab-description">{{ event.description }}</div>

            <div class="signup-details">
                <div class="signup-count">Prijave: {{ event.signups.length }}</div>
                <SignupCharacterRow v-for="signup in event.signups" :key="signup.accountName" :signup="signup" />
            </div>

            <button v-if="confirmDelete" @click="deleteEvent" class="delete-event confirm">Confirm delete</button>
            <button v-else @click="confirmDelete = true" class="delete-event">Delete</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { format } from 'date-fns'

import SignupCharacterRow from '@/components/eventAdmin/SignupCharacterRow.vue'

export default {
    name: 'EventRow',

    props: {
        event: {
            required: true,
            type: Object,
        },

        token: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            confirmDelete: false,
        }
    },

    created() {},

    components: { SignupCharacterRow },

    computed: {
        eventTime() {
            return this.event.time ? `${format(new Date(this.event.time), 'EEEE dd.MM.yyyy. HH:mm')}h` : '//'
        },

        eventEnded() {
            const now = Date.now()

            return this.event.time < now
        },
    },

    methods: {
        deleteEvent() {
            const url = process.env.NODE_ENV === 'production' ? '/signups/events/' : 'http://localhost/signups/events/'

            axios
                .delete(url, { data: { token: this.token, eventId: this.event.id } })
                .then(() => {
                    this.$emit('fetchEvents')
                })
                .catch(error => {
                    console.log(error)

                    alert(error.response.data?.error)
                })
        },
    },
}
</script>
