<template>
    <div class="event-management">
        <button @click="fetchEvents" type="button" class="fetch-events">Refresh</button>

        <form @submit.prevent="createEvent" class="new-event-form">
            <h1>Hai, {{ userPosting }}</h1>

            <div class="form-group">
                <label>Tip</label>
                <div class="input-group">
                    <select v-model="activeTemplate">
                        <option v-for="template in templatesDdl" :key="template" :value="template">
                            {{ template }}
                        </option>
                    </select>
                    <button @click="applyTemplate" type="button">Popuni</button>
                </div>
            </div>

            <div class="form-group">
                <label>Naziv eventa</label>
                <input v-model="eventFormData.name" type="text" placeholder="(min 10 znakova)" />
            </div>

            <div class="form-group">
                <label>Opis</label>
                <input v-model="eventFormData.description" type="text" placeholder="(min 10 znakova)" />
            </div>

            <div class="form-group">
                <label>Discord ping opis (markdown)</label>
                <textarea v-model="discordFormData.description" rows="5" placeholder="(min 10 znakova)"></textarea>
            </div>

            <div class="form-group">
                <label>Discord ping slika</label>
                <input v-model="discordFormData.image.url" type="text" />
            </div>

            <div class="form-group">
                <label>Tip</label>
                <select v-model="eventFormData.type">
                    <option v-for="type in eventTypes" :key="type" :value="type">
                        {{ type }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label>Vrijeme</label>
                <input v-model="eventFormData.time" type="datetime-local" />
            </div>

            <div class="add-event-actions">
                <button type="submit" class="submit" :disabled="submitDisabled">Dodaj event</button>
            </div>
        </form>

        <div v-if="events" class="events">
            <EventRow
                v-for="event in events"
                :key="event.id"
                @fetchEvents="fetchEvents"
                :event="event"
                :token="token"
            />
        </div>

        <div v-else class="loader"></div>
    </div>
</template>

<script>
import axios from 'axios'

import EventRow from '@/components/eventAdmin/EventRowAdmin.vue'

import fetchEvents from '@/mixins/fetchEvents'

import EventTypes from '@/utils/EventTypes'

const MIN_STRING_LENGTH = 10

export default {
    name: 'EventManagement',

    props: {
        token: {
            required: true,
            type: String,
        },

        userPosting: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            eventFormData: {
                name: '',
                description: '',
                type: 'pof',
                time: '',
            },

            discordFormData: {
                title: '',
                color: 16777215,
                description: '',
                timestamp: '',
                url: 'https://croatian-maniacs.com/event-signup',
                image: {
                    url: '',
                },

                footer: {
                    text: `Added by: ${this.userPosting}`,
                },
            },

            activeTemplate: 'strikeNight',

            templates: {
                strikeNight: {
                    name: 'Strike night - All the strikes!',
                    description: 'Svi IBS + EoD strikesi + Dragonstorm (ako stignemo)',
                    type: 'pof',
                    time: '',
                },

                raidTraining: {
                    name: 'Raid Training: Wing X',
                    description: 'https://croatian-maniacs.com/raids',
                    type: 'hot',
                    time: '',
                },
            },

            eventTypes: EventTypes,
        }
    },

    mixins: [fetchEvents],

    created() {
        this.fetchEvents()
    },

    components: { EventRow },

    computed: {
        submitDisabled() {
            return (
                Object.values(this.eventFormData).includes('') ||
                this.discordFormData.description.length < MIN_STRING_LENGTH ||
                this.eventFormData.name.length < MIN_STRING_LENGTH ||
                this.eventFormData.description.length < MIN_STRING_LENGTH
            )
        },

        templatesDdl() {
            return Object.keys(this.templates)
        },
    },

    methods: {
        createEvent() {
            const url = process.env.NODE_ENV === 'production' ? '/signups/events/' : 'http://localhost/signups/events/'

            const payload = {
                event: {
                    ...this.eventFormData,
                    time: new Date(this.eventFormData.time).getTime(),
                },

                ping: {
                    embeds: [
                        {
                            ...this.discordFormData,
                            timestamp: new Date().toISOString(),
                        },
                    ],
                },

                token: this.token,
            }

            axios
                .post(url, payload)
                .then(() => this.fetchEvents())
                .catch(error => {
                    console.log(error)

                    alert(error.response.data?.error)
                })
        },

        applyTemplate() {
            this.eventFormData = this.templates[this.activeTemplate]
        },
    },

    watch: {
        'eventFormData.name': function (val) {
            this.discordFormData.title = val
        },
    },
}
</script>
