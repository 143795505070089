<template>
    <div class="signup-character">
        <span class="account-name">{{ signup.accountName }} </span> - {{ signup.character }} ({{ signupTime }})
    </div>
</template>

<script>
import { format } from 'date-fns'

export default {
    name: 'SignupCharacterRow',

    props: {
        signup: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {}
    },

    created() {},

    components: {},

    computed: {
        signupTime() {
            return this.signup.signupTime ? `${format(new Date(this.signup.signupTime), 'dd.MM.yyyy. HH:mm')}h` : '//'
        },
    },

    methods: {},
}
</script>
